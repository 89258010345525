import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Input from '@mui/material/Input';
import { Typography, Container, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TextField, MenuItem, Divider, Paper } from '@mui/material';


const RenderData = ({ data }) => {
    const renderRow = (data, level = 0) => {
      return Object.entries(data).map(([key, value]) => {
        if (typeof value === 'object') {
          return (
            <>
              <TableRow key={key}>
                <TableCell colSpan={2} style={{ fontWeight: 'bold', paddingLeft: `${20 * level}px` }}>{key}</TableCell>
              </TableRow>
              {renderRow(value, level + 1)}
            </>
          );
        } else {
          return (
            <TableRow key={key}>
              <TableCell>{key}</TableCell>
              <TableCell>{value}</TableCell>
            </TableRow>
          );
        }
      });
    };
  
    return (
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            {renderRow(data)}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

const FileUpload = () => {
    const [file, setFile] = useState(null);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [result, setResult] = useState(null);
    const [dropdown1Value, setDropdown1Value] = useState('');
    const [dropdown2Value, setDropdown2Value] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://c82b-2402-d000-a500-3314-313e-90dd-67b3-c11e.ngrok-free.app/api/items');
                const result = response.data;
                setData(result);
                console.log('result', result);
            } catch (error) {
                setError(error.message || 'An error occurred');
            }
        };

        fetchData();
    }, []);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleUpload = async () => {
        try {
            setLoading(true); // Set loading state to true when upload starts
            const apiUrl = `https://c82b-2402-d000-a500-3314-313e-90dd-67b3-c11e.ngrok-free.app/api/read/?col1_name=${dropdown1Value}&col2_name=${dropdown1Value}`;
            const formData = new FormData();
            formData.append('file_uploaded', file);

            const response = await fetch(apiUrl, {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                const data = await response.json();
                setResult(data);
                console.log('Response data:', data);
                console.log('Server message:', data.message);
                console.log('File uploaded successfully');
            } else {
                console.error('Error uploading file');
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false); // Set loading state to false when upload is complete (whether successful or not)
        }
    };

    const handleDropdown1Change = (event) => {
        setDropdown1Value(event.target.value);
    };

    const handleDropdown2Change = (event) => {
        setDropdown2Value(event.target.value);
    };

    return (
        <>
            <Container className="hero-section">
                <div className="div">
                    <h1>RMH Proposal Tool</h1>
                    <Input style={{ margin: '10px' }} className='' type='file' onChange={handleFileChange} placeholder="Enter your text" />
                    <Button style={{ margin: '10px' }} onClick={handleUpload} size="small" component="label" variant="contained" startIcon={<CloudUploadIcon />}>
                        Upload file
                    </Button>



                    <div className='flex'>

                        <TextField
                            size="small"
                            select
                            label="Select Column"
                            value={dropdown1Value}
                            onChange={handleDropdown1Change}
                            style={{ margin: '10px', width: '200px' }}
                        >
                            {[
                                { value: "column_1", label: "Column 1" },
                                { value: "column_2", label: "Column 2" },
                                { value: "column_3", label: "Column 3" },
                                { value: "column_4", label: "Column 4" },
                                { value: "column_5", label: "Column 5" }
                            ].map((column) => (
                                <MenuItem key={column.value} value={column.value}>
                                    {column.label}
                                </MenuItem>
                            ))}
                        </TextField>

                    </div>

                    {result !== null && (
                        <>
                         <RenderData title="Total Department Expenses" data={{
                                "Total Departmental Expenses": result?.departmental_expenses,
                                "Rooms": {
                                    "Payroll-Related Expenses": {
                                        "Payroll Expenses": result?.departmental_expenses_rooms_payroll_expenses,
                                        "departmental_expenses_rooms_payroll_retirement_contribution": result?.departmental_expenses_rooms_payroll_retirement_contribution,
                                        "departmental_expenses_rooms_payroll_unemployment_insurance": result?.departmental_expenses_rooms_payroll_unemployment_insurance,
                                        "departmental_expenses_rooms_payroll_medical_insurance": result?.departmental_expenses_rooms_payroll_medical_insurance,
                                        "departmental_expenses_rooms_payroll_state_unemployment_insurance": result?.departmental_expenses_rooms_payroll_state_unemployment_insurance,
                                        "departmental_expenses_rooms_payroll_other_payroll_taxes": result?.departmental_expenses_rooms_payroll_other_payroll_taxes,
                                    },
                                    "Supplemental Pay": {
                                        "Supplemental Pay": result?.departmental_expenses_rooms_supplemental_pay,
                                        "departmental_expenses_rooms_supplemental_pay_holiday_pay": result?.departmental_expenses_rooms_supplemental_pay_holiday_pay,
                                        "departmental_expenses_rooms_supplemental_pay_sick_pay": result?.departmental_expenses_rooms_supplemental_pay_sick_pay,
                                        "departmental_expenses_rooms_supplemental_pay_vacation_paid_time_Off": result?.departmental_expenses_rooms_supplemental_pay_vacation_paid_time_Off,
                                        "departmental_expenses_rooms_supplemental_pay_other_pay": result?.departmental_expenses_rooms_supplemental_pay_other_pay,
                                    },
                                    "Employee Benefits": {
                                        "Employee Benefits": result?.departmental_expenses_rooms_employee_benefits,
                                        "departmental_expenses_rooms_employee_benefits_contributiory_savings_plan": result?.departmental_expenses_rooms_employee_benefits_contributiory_savings_plan,
                                        "departmental_expenses_rooms_employee_benefits_group_other_insurance": result?.departmental_expenses_rooms_employee_benefits_group_other_insurance,
                                        "departmental_expenses_rooms_employee_benefits_workers_compensation": result?.departmental_expenses_rooms_employee_benefits_workers_compensation,
                                    },
                                    "Other Expenses": {
                                        "Other": result?.departmental_expenses_rooms_other,
                                        "departmental_expenses_rooms_other_employee_holiday_gifts": result?.departmental_expenses_rooms_other_employee_holiday_gifts,
                                        "departmental_expenses_rooms_other_travel_other_exepense": result?.departmental_expenses_rooms_other_travel_other_exepense,
                                        "departmental_expenses_rooms_other_equipment_rental": result?.departmental_expenses_rooms_other_equipment_rental,
                                        "departmental_expenses_rooms_other_commissions_and_fees_group": result?.departmental_expenses_rooms_other_commissions_and_fees_group,
                                        "departmental_expenses_rooms_other_travel_agent_commissions": result?.departmental_expenses_rooms_other_travel_agent_commissions,
                                        "departmental_expenses_rooms_other_dues_and_subscriptions_expense": result?.departmental_expenses_rooms_other_dues_and_subscriptions_expense,
                                        "departmental_expenses_rooms_other_os_operating_supplies": result?.departmental_expenses_rooms_other_os_operating_supplies,
                                        "departmental_expenses_rooms_other_os_paper_and_plastics": result?.departmental_expenses_rooms_other_os_paper_and_plastics,
                                        "departmental_expenses_rooms_other_express_mail_and_courier": result?.departmental_expenses_rooms_other_express_mail_and_courier,
                                        "departmental_expenses_rooms_other_printing_stationery": result?.departmental_expenses_rooms_other_printing_stationery,
                                        "departmental_expenses_rooms_other_cleaning_supplies": result?.departmental_expenses_rooms_other_cleaning_supplies,
                                        "departmental_expenses_rooms_other_laundry_outsourced": result?.departmental_expenses_rooms_other_laundry_outsourced,
                                        "departmental_expenses_rooms_other_laundry": result?.departmental_expenses_rooms_other_laundry,
                                        "departmental_expenses_rooms_other_complimentary_inroom_media_ent": result?.departmental_expenses_rooms_other_complimentary_inroom_media_ent,
                                        "departmental_expenses_rooms_other_comp_other_gifts_and_services": result?.departmental_expenses_rooms_other_comp_other_gifts_and_services,
                                        "departmental_expenses_rooms_other_guest_relocation": result?.departmental_expenses_rooms_other_guest_relocation,
                                        "departmental_expenses_rooms_other_guest_supplies_expenses": result?.departmental_expenses_rooms_other_guest_supplies_expenses,
                                        "departmental_expenses_rooms_other_guest_supplies_brand_collateral": result?.departmental_expenses_rooms_other_guest_supplies_brand_collateral,
                                        "departmental_expenses_rooms_other_guest_transportation": result?.departmental_expenses_rooms_other_guest_transportation,
                                        "departmental_expenses_rooms_other_comp_breakfast": result?.departmental_expenses_rooms_other_comp_breakfast,
                                        "departmental_expenses_rooms_other_licensens_and_permits": result?.departmental_expenses_rooms_other_licensens_and_permits,
                                        "departmental_expenses_rooms_other_contract_services_general": result?.departmental_expenses_rooms_other_contract_services_general,
                                        "departmental_expenses_rooms_other_uniform_laundry": result?.departmental_expenses_rooms_other_uniform_laundry,
                                        "departmental_expenses_rooms_other_linen_expenses": result?.departmental_expenses_rooms_other_linen_expenses,
                                        "departmental_expenses_rooms_other_uniform_cost": result?.departmental_expenses_rooms_other_uniform_cost,
                                        "departmental_expenses_rooms_other_reservation_chain_expense": result?.departmental_expenses_rooms_other_reservation_chain_expense,
                                        "departmental_expenses_rooms_other_reservation_gds_expense": result?.departmental_expenses_rooms_other_reservation_gds_expense,
                                        "departmental_expenses_rooms_other_miscellaneous_expense": result?.departmental_expenses_rooms_other_miscellaneous_expense,


                                    },
                                    "Total Rooms Expenses": result?.departmental_expenses_rooms,
                                },
                                "F&B": {
                                    "Cost of Sales (COS)": {
                                        "Cost of Sales": result?.departmental_expenses_f_and_b_cost_of_sales,
                                        "departmental_expenses_f_and_b_cost_of_sales_food": result?.departmental_expenses_f_and_b_cost_of_sales_food,
                                        "departmental_expenses_f_and_b_cost_of_sales_beverage": result?.departmental_expenses_f_and_b_cost_of_sales_beverage,
                                        "departmental_expenses_f_and_b_cost_of_sales_other": result?.departmental_expenses_f_and_b_cost_of_sales_other,
                                    },
                                    "Salaries & Wages": {
                                        "Salaries and Wages": result?.departmental_expenses_f_and_b_salaries_and_wages,
                                        "departmental_expenses_f_and_b_salaries_and_wages_restaurant_labor": result?.departmental_expenses_f_and_b_salaries_and_wages_restaurant_labor,
                                        "departmental_expenses_f_and_b_salaries_and_wages_breakfast_gallary": result?.departmental_expenses_f_and_b_salaries_and_wages_breakfast_gallary,
                                        "departmental_expenses_f_and_b_salaries_and_wages_service_charge_distribution": result?.departmental_expenses_f_and_b_salaries_and_wages_service_charge_distribution,
                                        "departmental_expenses_f_and_b_salaries_and_wages_outside_labor": result?.departmental_expenses_f_and_b_salaries_and_wages_outside_labor,
                                    },
                                    "Payroll-Related Expenses":{
                                        "Payroll Related Expenses": result?.departmental_expenses_f_and_b_payroll_related_expenses,
                                        "departmental_expenses_f_and_b_payroll_retirement_contribution": result?.departmental_expenses_f_and_b_payroll_retirement_contribution,
                                        "departmental_expenses_f_and_b_payroll_unemployment_insurance": result?.departmental_expenses_f_and_b_payroll_unemployment_insurance,
                                        "departmental_expenses_f_and_b_payroll_medical_insurance": result?.departmental_expenses_f_and_b_payroll_medical_insurance,
                                        "departmental_expenses_f_and_b_payroll_state_unemployment_insurance": result?.departmental_expenses_f_and_b_payroll_state_unemployment_insurance,
                                        "departmental_expenses_f_and_b_payroll_other_payroll_taxes": result?.departmental_expenses_f_and_b_payroll_other_payroll_taxes,
                        

                                    },
                                    "Supplemental Pay": {
                                        "departmental_expenses_f_and_b_supplemental_pay_holiday_pay": result?.departmental_expenses_f_and_b_supplemental_pay_holiday_pay,
                                        "departmental_expenses_f_and_b_supplemental_pay_sick_pay": result?.departmental_expenses_f_and_b_supplemental_pay_sick_pay,
                                        "departmental_expenses_f_and_b_supplemental_pay_vacation_paid_time_Off": result?.departmental_expenses_f_and_b_supplemental_pay_vacation_paid_time_Off,
                                        "departmental_expenses_f_and_b_supplemental_pay_other_pay": result?.departmental_expenses_f_and_b_supplemental_pay_other_pay
                                    },
                                    "Employee Benefits":{
                                        "departmental_expenses_f_and_b_employee_benefits_contributiory_savings_plan": result?.departmental_expenses_f_and_b_employee_benefits_contributiory_savings_plan,
                                        "departmental_expenses_f_and_b_employee_benefits_group_other_insurance": result?.departmental_expenses_f_and_b_employee_benefits_group_other_insurance,
                                        "departmental_expenses_f_and_b_employee_benefits_workers_compensation": result?.departmental_expenses_f_and_b_employee_benefits_workers_compensation,
                        
                                    },
                                    "Departmental Expenses F&B": result?.departmental_expenses_f_and_b,
                                    "Other":{
                                        "Other": result?.departmental_expenses_f_and_b_other,
                                        "departmental_expenses_f_and_b_other_dues_subscriptions": result?.departmental_expenses_f_and_b_other_dues_subscriptions,
                                        "departmental_expenses_f_and_b_other_os_operating_supplies": result?.departmental_expenses_f_and_b_other_os_operating_supplies,
                                        "departmental_expenses_f_and_b_other_os_paper_and_plastics": result?.departmental_expenses_f_and_b_other_os_paper_and_plastics,
                                        "departmental_expenses_f_and_b_other_printing_stationery": result?.departmental_expenses_f_and_b_other_printing_stationery,
                                        "departmental_expenses_f_and_b_other_cleaning_supplies": result?.departmental_expenses_f_and_b_other_cleaning_supplies,
                                        "departmental_expenses_f_and_b_other_laundry_outsourced": result?.departmental_expenses_f_and_b_other_laundry_outsourced,
                                        "departmental_expenses_f_and_b_other_laundry": result?.departmental_expenses_f_and_b_other_laundry,
                                        "departmental_expenses_f_and_b_other_licensens_and_permits": result?.departmental_expenses_f_and_b_other_licensens_and_permits,
                                        "departmental_expenses_f_and_b_other_contract_services_general": result?.departmental_expenses_f_and_b_other_contract_services_general,
                                        "departmental_expenses_f_and_b_other_flatware": result?.departmental_expenses_f_and_b_other_flatware,
                                        "departmental_expenses_f_and_b_other_glassware": result?.departmental_expenses_f_and_b_other_glassware,
                                        "departmental_expenses_f_and_b_other_banquet": result?.departmental_expenses_f_and_b_other_banquet,
                                        "departmental_expenses_f_and_b_other_menu_beverage_license": result?.departmental_expenses_f_and_b_other_menu_beverage_license,
                                        "departmental_expenses_f_and_b_other_music_license": result?.departmental_expenses_f_and_b_other_music_license,
                        
                                    },
                                },
                                "Departmental Expenses Other": result?.departmental_expenses_other,
                                "Undistributed Operating Expenses": result?.departmental_expenses,
                                "Salary and wages": {
                                    "undistributed_expenses_administration_general_salaries_and_wages_ag_executive_office": result?.undistributed_expenses_administration_general_salaries_and_wages_ag_executive_office,
                                    "undistributed_expenses_administration_general_salaries_and_wages_general_manager": result?.undistributed_expenses_administration_general_salaries_and_wages_general_manager,
                                    "undistributed_expenses_administration_general_salaries_and_wages_outside_labor": result?.undistributed_expenses_administration_general_salaries_and_wages_outside_labor,
                                    "undistributed_expenses_administration_general_salaries_and_wages_bonuses_incentives": result?.undistributed_expenses_administration_general_salaries_and_wages_bonuses_incentives,

                                },
                                "Payroll-Related Expenses":{
                                    "undistributed_expenses_administration_general_payroll_retirement_contribution":result?.undistributed_expenses_administration_general_payroll_retirement_contribution,
                                    "undistributed_expenses_administration_general_payroll_unemployment_insurance" :result?.undistributed_expenses_administration_general_payroll_unemployment_insurance,
                                    "undistributed_expenses_administration_general_payroll_medical_insurance":result?.undistributed_expenses_administration_general_payroll_medical_insurance,
                                    "undistributed_expenses_administration_general_payroll_state_unemployment_insurance":result?.undistributed_expenses_administration_general_payroll_state_unemployment_insurance,
                                    "undistributed_expenses_administration_general_payroll_other_payroll_taxes":result?.undistributed_expenses_administration_general_payroll_other_payroll_taxes,
                    
                                },
                                "Supplemental Pay":{
                                    "undistributed_expenses_administration_general_supplemental_pay_holiday_pay":result?.undistributed_expenses_administration_general_supplemental_pay_holiday_pay,
                                    "undistributed_expenses_administration_general_supplemental_pay_sick_pay":result?.undistributed_expenses_administration_general_supplemental_pay_sick_pay, 
                                    "undistributed_expenses_administration_general_supplemental_pay_vacation_paid_time_Off":result?.undistributed_expenses_administration_general_supplemental_pay_vacation_paid_time_Off, 
                                    "undistributed_expenses_administration_general_supplemental_pay_other_pay":result?.undistributed_expenses_administration_general_supplemental_pay_other_pay,
                                    
                                },
                                "Employee Benefits":{
                                    "undistributed_expenses_administration_general_employee_benefits_contributiory_savings_plan":result?.undistributed_expenses_administration_general_employee_benefits_contributiory_savings_plan,
                                    "undistributed_expenses_administration_general_employee_benefits_group_other_insurance":result?.undistributed_expenses_administration_general_employee_benefits_group_other_insurance, 
                                    "undistributed_expenses_administration_general_employee_benefits_workers_compensation":result?.undistributed_expenses_administration_general_employee_benefits_workers_compensation, 
                    
                                },
                                "Other Expenses": {
                                    "undistributed_expenses_administration_general_other_employee_holiday_gifts": result?.undistributed_expenses_administration_general_other_employee_holiday_gifts,
                                    "undistributed_expenses_administration_general_other_employee_events": result?.undistributed_expenses_administration_general_other_employee_events,
                                    "undistributed_expenses_administration_general_other_employee_awards": result?.undistributed_expenses_administration_general_other_employee_awards,
                                    "undistributed_expenses_administration_general_other_employee_recruit": result?.undistributed_expenses_administration_general_other_employee_recruit,
                                    "undistributed_expenses_administration_general_other_social_recreation_100": result?.undistributed_expenses_administration_general_other_social_recreation_100,
                                    "undistributed_expenses_administration_general_other_social_recreation_executive": result?.undistributed_expenses_administration_general_other_social_recreation_executive,
                                    "undistributed_expenses_administration_general_other_hr": result?.undistributed_expenses_administration_general_other_hr,
                                    "undistributed_expenses_administration_general_other_training": result?.undistributed_expenses_administration_general_other_training,
                                    "undistributed_expenses_administration_general_other_audit": result?.undistributed_expenses_administration_general_other_audit,
                                    "undistributed_expenses_administration_general_other_pcard_rebates": result?.undistributed_expenses_administration_general_other_pcard_rebates,
                                    "undistributed_expenses_administration_general_other_centralized_accounting_charges": result?.undistributed_expenses_administration_general_other_centralized_accounting_charges,
                                    "undistributed_expenses_administration_general_other_legal_servicesd": result?.undistributed_expenses_administration_general_other_legal_servicesd,
                                    "undistributed_expenses_administration_general_other_professional_fees_other": result?.undistributed_expenses_administration_general_other_professional_fees_other,
                                    "undistributed_expenses_administration_general_other_travel_airfare": result?.undistributed_expenses_administration_general_other_travel_airfare,
                                    "undistributed_expenses_administration_general_other_travel_other": result?.undistributed_expenses_administration_general_other_travel_other,
                                    "undistributed_expenses_administration_general_other_travel_lodging": result?.undistributed_expenses_administration_general_other_travel_lodging,
                                    "undistributed_expenses_administration_general_other_travel_auto_expense": result?.undistributed_expenses_administration_general_other_travel_auto_expense,
                                    "undistributed_expenses_administration_general_other_business_meals_off_property": result?.undistributed_expenses_administration_general_other_business_meals_off_property,
                                    "undistributed_expenses_administration_general_other_provision_expense": result?.undistributed_expenses_administration_general_other_provision_expense,
                                    "undistributed_expenses_administration_general_other_provision_direct_write_off": result?.undistributed_expenses_administration_general_other_provision_direct_write_off,
                                    "undistributed_expenses_administration_general_other_dues_and_subscriptions": result?.undistributed_expenses_administration_general_other_dues_and_subscriptions,
                                    "undistributed_expenses_administration_general_other_os_operating_supplies": result?.undistributed_expenses_administration_general_other_os_operating_supplies,
                                    "undistributed_expenses_administration_general_other_express_mail_and_courier": result?.undistributed_expenses_administration_general_other_express_mail_and_courier,
                                    "undistributed_expenses_administration_general_other_print_stationery": result?.undistributed_expenses_administration_general_other_print_stationery,
                                    "undistributed_expenses_administration_general_other_licenses_permits": result?.undistributed_expenses_administration_general_other_licenses_permits,
                                    "undistributed_expenses_administration_general_other_cash_overage_and_shortage": result?.undistributed_expenses_administration_general_other_cash_overage_and_shortage,
                                    "undistributed_expenses_administration_general_other_contract_services_general": result?.undistributed_expenses_administration_general_other_contract_services_general,
                                    "undistributed_expenses_administration_general_other_contract_security": result?.undistributed_expenses_administration_general_other_contract_security,
                                    "undistributed_expenses_administration_general_other_uniform_costs": result?.undistributed_expenses_administration_general_other_uniform_costs,
                                    "undistributed_expenses_administration_general_other_miscellaneous_expense": result?.undistributed_expenses_administration_general_other_miscellaneous_expense,
                                    "undistributed_expenses_administration_general_other_bank_charges": result?.undistributed_expenses_administration_general_other_bank_charges,
                                    "undistributed_expenses_administration_general_other_cc_commissions_amex": result?.undistributed_expenses_administration_general_other_cc_commissions_amex,
                                    "undistributed_expenses_administration_general_other_cc_commissions_visa": result?.undistributed_expenses_administration_general_other_cc_commissions_visa,
                                    "undistributed_expenses_administration_general_other_cc_commissions_mastercard": result?.undistributed_expenses_administration_general_other_cc_commissions_mastercard,
                                    "undistributed_expenses_administration_general_other_cc_commissions_other": result?.undistributed_expenses_administration_general_other_cc_commissions_other,
                                },
                            }} />

                        </>
                    )}



                </div>
            </Container>
            {data && (
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell>Occupancy</TableCell>
                                <TableCell>Average Rate</TableCell>
                                <TableCell>RevPAR</TableCell>
                                <TableCell>Total Operating Revenue</TableCell>
                                <TableCell>Operating Revenue Rooms</TableCell>
                                <TableCell>Operating revenue f and b</TableCell>
                                <TableCell>Other Rrevenue Operated Departments</TableCell>
                                <TableCell>Total Departmental Expenses</TableCell>
                                <TableCell>Departmental Expenses Rooms</TableCell>
                                <TableCell>Departmental Expenses f and b</TableCell>
                                <TableCell>Departmental Expenses Other</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((item) => (
                                <TableRow key={item.date}>
                                    <TableCell>{item?.date}</TableCell>
                                    <TableCell>{item?.occupancy}</TableCell>
                                    <TableCell>{item?.average_rate}</TableCell>
                                    <TableCell>{item?.rev_par}</TableCell>
                                    <TableCell>{item?.total_operating_revenue}</TableCell>
                                    <TableCell>{item?.operating_revenue_rooms}</TableCell>
                                    <TableCell>{item?.operating_revenue_f_and_b}</TableCell>
                                    <TableCell>{item?.other_revenue_operated_departments}</TableCell>
                                    <TableCell>{item?.total_departmental_expenses}</TableCell>
                                    <TableCell>{item?.departmental_expenses_rooms}</TableCell>
                                    <TableCell>{item?.departmental_expenses_f_and_b}</TableCell>
                                    <TableCell>{item?.departmental_expenses_other}</TableCell>
                                    <TableCell>{item?.total_undistributed_expenses}</TableCell>
                                    <TableCell>{item?.undistributed_expenses_administration_general}</TableCell>
                                    <TableCell>{item?.undistributed_expenses_information_telecomm}</TableCell>
                                    <TableCell>{item?.undistributed_expenses_sales_marketing}</TableCell>
                                    <TableCell>{item?.undistributed_expenses_franchise}</TableCell>
                                    <TableCell>{item?.undistributed_expenses_property_operations_maintenance}</TableCell>
                                    <TableCell>{item?.undistributed_expenses_utilities}</TableCell>
                                    <TableCell>{item?.income_before_non_oper_expenses}</TableCell>
                                    <TableCell>{item?.management_fees}</TableCell>
                                    <TableCell>{item?.total_non_operating_expenses}</TableCell>
                                    <TableCell>{item?.non_operating_expenses_insurance}</TableCell>
                                    <TableCell>{item?.non_operating_expenses_other}</TableCell>
                                    <TableCell>{item?.non_operating_expenses_property_tax}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </>
    );
};

export default FileUpload;