import './App.css';
import FileUpload from './components/FileUpload';

function App() {
  return (
    <>
     <FileUpload></FileUpload>
    </>
  );
}

export default App;
